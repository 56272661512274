import React from 'react';
import autobind from 'autobind-decorator';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

// Dynamic imports don't seem to work.
import logo_01_01 from '../images/logos/01-01.svg';
import logo_01_02 from '../images/logos/01-02.svg';
import logo_01_03 from '../images/logos/01-03.svg';
import logo_01_04 from '../images/logos/01-04.svg';
import logo_01_05 from '../images/logos/01-05.svg';
import logo_01_06 from '../images/logos/01-06.svg';
import logo_01_07 from '../images/logos/01-07.svg';
import logo_01_08 from '../images/logos/01-08.svg';

import logo_02_01 from '../images/logos/02-01.svg';
import logo_02_02 from '../images/logos/02-02.svg';
import logo_02_03 from '../images/logos/02-03.svg';
import logo_02_04 from '../images/logos/02-04.svg';
import logo_02_05 from '../images/logos/02-05.svg';
import logo_02_06 from '../images/logos/02-06.svg';
import logo_02_07 from '../images/logos/02-07.svg';
import logo_02_08 from '../images/logos/02-08.svg';

import logo_03_01 from '../images/logos/03-01.svg';
import logo_03_02 from '../images/logos/03-02.svg';
import logo_03_03 from '../images/logos/03-03.svg';
import logo_03_04 from '../images/logos/03-04.svg';
import logo_03_05 from '../images/logos/03-05.svg';
import logo_03_06 from '../images/logos/03-06.svg';
import logo_03_07 from '../images/logos/03-07.svg';
import logo_03_08 from '../images/logos/03-08.svg';

import logo_04_01 from '../images/logos/04-01.svg';
import logo_04_02 from '../images/logos/04-02.svg';
import logo_04_03 from '../images/logos/04-03.svg';

import logo_05_01 from '../images/logos/05-01.svg';
import logo_05_02 from '../images/logos/05-02.svg';
import logo_05_03 from '../images/logos/05-03.svg';
import logo_05_04 from '../images/logos/05-04.svg';
import logo_05_05 from '../images/logos/05-05.svg';
import logo_05_06 from '../images/logos/05-06.svg';
import logo_05_07 from '../images/logos/05-07.svg';
import logo_05_08 from '../images/logos/05-08.svg';

import logo_06_01 from '../images/logos/06-01.svg';
import logo_06_02 from '../images/logos/06-02.svg';
import logo_06_03 from '../images/logos/06-03.svg';
import logo_06_04 from '../images/logos/06-04.svg';
import logo_06_05 from '../images/logos/06-05.svg';

import logo_07_01 from '../images/logos/07-01.svg';
import logo_07_02 from '../images/logos/07-02.svg';
import logo_07_03 from '../images/logos/07-03.svg';
import logo_07_04 from '../images/logos/07-04.svg';
import logo_07_05 from '../images/logos/07-05.svg';
import logo_07_06 from '../images/logos/07-06.svg';
import logo_07_07 from '../images/logos/07-07.svg';
import logo_07_08 from '../images/logos/07-08.svg';
import logo_07_09 from '../images/logos/07-09.svg';
import logo_07_10 from '../images/logos/07-10.svg';
import logo_07_11 from '../images/logos/07-11.svg';
import logo_07_12 from '../images/logos/07-12.svg';
import logo_07_13 from '../images/logos/07-13.svg';
import logo_07_14 from '../images/logos/07-14.svg';
import logo_07_15 from '../images/logos/07-15.svg';
import logo_07_16 from '../images/logos/07-16.svg';
import logo_07_17 from '../images/logos/07-17.svg';
import logo_07_18 from '../images/logos/07-18.svg';
import logo_07_19 from '../images/logos/07-19.svg';

import logo_08_01 from '../images/logos/08-01.svg';
import logo_08_02 from '../images/logos/08-02.svg';
import logo_08_03 from '../images/logos/08-03.svg';
import logo_08_04 from '../images/logos/08-04.svg';
import logo_08_05 from '../images/logos/08-05.svg';

declare module 'react-images' {
  interface LightboxProps {
    theme?: any;
  }
}

export const logos = [
  { src: logo_08_01, alt: '', height: 361, width: 576 },
  { src: logo_08_02, alt: '', height: 648, width: 648 },
  { src: logo_08_03, alt: '', height: 802, width: 981 },
  { src: logo_08_04, alt: '', height: 792, width: 792 },
  { src: logo_08_05, alt: '', height: 568, width: 612 },
  
  { src: logo_07_01, alt: '', height: 562, width: 497 },
  { src: logo_07_02, alt: '', height: 509, width: 256 },
  { src: logo_07_03, alt: '', height: 536, width: 465 },
  { src: logo_07_04, alt: '', height: 579, width: 522 },
  { src: logo_07_05, alt: '', height: 562, width: 497 },
  { src: logo_07_06, alt: '', height: 562, width: 379 },
  { src: logo_07_07, alt: '', height: 562, width: 311 },
  { src: logo_07_08, alt: '', height: 562, width: 497 },
  { src: logo_07_09, alt: '', height: 562, width: 497 },
  { src: logo_07_10, alt: '', height: 641, width: 233 },
  { src: logo_07_11, alt: '', height: 1600, width: 1046 },
  { src: logo_07_12, alt: '', height: 562, width: 497 },
  { src: logo_07_13, alt: '', height: 912, width: 624 },
  { src: logo_07_14, alt: '', height: 1056, width: 1056 },
  { src: logo_07_15, alt: '', height: 562, width: 497 },
  { src: logo_07_16, alt: '', height: 562, width: 497 },
  { src: logo_07_17, alt: '', height: 1051, width: 965 },
  { src: logo_07_18, alt: '', height: 701, width: 349 },
  { src: logo_07_19, alt: '', height: 562, width: 497 },

  { src: logo_05_01, alt: '', height: 273.8, width: 400 },
  { src: logo_05_02, alt: '', height: 88.36, width: 400 },
  { src: logo_05_03, alt: '', height: 354.39, width: 400 },
  { src: logo_05_04, alt: '', height: 354.39, width: 400 },
  { src: logo_05_05, alt: '', height: 407.27, width: 400 },
  { src: logo_05_06, alt: '', height: 135.55, width: 400 },
  { src: logo_05_07, alt: '', height: 273.8, width: 400 },
  { src: logo_05_08, alt: '', height: 257.92, width: 400 },

  { src: logo_06_01, alt: '', height: 200, width: 400 },
  { src: logo_06_02, alt: '', height: 228.75, width: 400 },
  { src: logo_06_03, alt: '', height: 273.8, width: 400 },
  { src: logo_06_04, alt: '', height: 354.39, width: 400 },
  { src: logo_06_05, alt: '', height: 349.11, width: 400 },

  { src: logo_01_01, alt: '', height: 239.9, width: 400 },
  { src: logo_01_02, alt: '', height: 314.45, width: 400 },
  { src: logo_01_03, alt: '', height: 284.14, width: 400 },
  { src: logo_01_04, alt: '', height: 340.18, width: 400 },
  { src: logo_01_05, alt: '', height: 288.09, width: 400 },
  { src: logo_01_06, alt: '', height: 334.8, width: 400 },
  { src: logo_01_07, alt: '', height: 187.74, width: 400 },
  { src: logo_01_08, alt: '', height: 303.25, width: 400 },

  { src: logo_02_01, alt: '', height: 308.38, width: 400 },
  { src: logo_02_02, alt: '', height: 292.58, width: 400 },
  { src: logo_02_03, alt: '', height: 272.6, width: 400 },
  { src: logo_02_04, alt: '', height: 269.9, width: 400 },
  { src: logo_02_05, alt: '', height: 377.25, width: 400 },
  { src: logo_02_06, alt: '', height: 297.9, width: 400 },
  { src: logo_02_07, alt: '', height: 400.6, width: 400 },
  { src: logo_02_08, alt: '', height: 392.19, width: 400 },

  { src: logo_03_01, alt: '', height: 364.11, width: 400 },
  { src: logo_03_02, alt: '', height: 408.58, width: 400 },
  { src: logo_03_03, alt: '', height: 434.8, width: 400 },
  { src: logo_03_04, alt: '', height: 365.71, width: 400 },
  { src: logo_03_05, alt: '', height: 366.05, width: 400 },
  { src: logo_03_06, alt: '', height: 434.8, width: 400 },
  { src: logo_03_07, alt: '', height: 400.04, width: 400 },
  { src: logo_03_08, alt: '', height: 70.31, width: 400 },

  { src: logo_04_01, alt: '', height: 400.85, width: 400 },
  { src: logo_04_02, alt: '', height: 214.03, width: 400 },
  { src: logo_04_03, alt: '', height: 135.76, width: 400 },
];

class LogosPage extends React.Component<{}, LogosPageState> {
  hammer: HammerManager | undefined;
  constructor(props: {}) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
    };
  }

  @autobind
  openLightbox(event: React.MouseEvent, obj: any) {
    this.setState(
      {
        currentImage: obj.index,
        lightboxIsOpen: true,
      },
      () => {
        setTimeout(async () => {
          const lb = document.querySelector('#lightboxBackdrop') as HTMLElement;
          if (!lb) {
            return;
          }
          const Hammer = await import('hammerjs');
          this.hammer = new Hammer.default(lb);
          this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
          this.hammer.on('swipe', (ev) => {
            if (ev.type === 'swipe' && ev.offsetDirection === Hammer.DIRECTION_LEFT) {
              this.gotoNext();
            } else if (ev.type === 'swipe' && ev.offsetDirection === Hammer.DIRECTION_RIGHT) {
              this.gotoPrevious();
            } else if (ev.type === 'tap') {
              this.closeLightbox();
            }
          });
        });
      }
    );
  }

  @autobind
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
    
    if (this.hammer) {
      this.hammer.destroy();
      this.hammer = undefined;
    }
  }

  @autobind
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage > 0 ? this.state.currentImage - 1 : 0,
    });
  }

  @autobind
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage < logos.length - 1 ? this.state.currentImage + 1 : logos.length - 1,
    });
  }

  render() {
    return (
      <Layout pageTitle="Logos">
        <SEO title="Logos" />
        <div className="logos">
          <Gallery photos={logos} margin={10} width={700} columns={8} onClick={this.openLightbox} />
          <Lightbox
            images={logos}
            theme={{
              footerCount: {
                color: 'black'
              },
              image: {
                width: 400
              }
            }}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
      </Layout>
    );
  }
}

interface LogosPageState {
  currentImage: number;
  lightboxIsOpen: boolean;
}

export default LogosPage;
